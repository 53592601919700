<template>
    <div class="form-group">
        <label :for="label">{{ label }}</label>
        <div v-if="subtitle" class="subtitle">
            {{ subtitle }}
        </div>
        <div class="input-group" :class="input_group_class">
            <slot v-if="slots.default"/>
            <textarea
                    v-if="type === 'textarea' && (!use_only_slot || !slots.default)"
                    :disabled="disabled"
                    :placeholder="placeholder"
                    :rows="textarea_rows"
                    @input="update($event.target.value)"
            >{{ textAreaDefaultValue }}</textarea>
            <input
v-if="type !== 'textarea' && (!use_only_slot || !slots.default)"
                   :type="type"
                   :disabled="disabled"
                   :autocomplete="autocomplete"
                   :value="parent_ref"
                   :placeholder="placeholder"
                   @input="update($event.target.value)"
            >
            <!--
            isCorrect is a reactive variable that is used to show tick or cross icons based on the input.
            It is set to 0 by default, 1 if the input is incorrect, and 2 if the input is correct.
            -->
            <div
v-if="isCorrect !== 0" class="is-correct"
                 :class="{
                    'is-correct--true': isCorrect === 2,
                    'is-correct--false': isCorrect === 1,
                 }"
            />
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    label: {
        type: String,
        required: true,
    },
    subtitle: {
        type: String,
        default: null,
    },
    parent_ref: {
        type: String,
    },
    type: {
        type: String,
        default: "text",
    },
    placeholder: {
        type: String,
        default: "",
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    check: {
        type: Function,
        default: null
    },
    autocomplete: {
        type: String,
        default: "off",
    },
    textarea_rows: {
        type: Number,
        default: 3,
    },
    input_group_class: {
        type: String,
        default: "",
    },
    use_only_slot: {
        type: Boolean,
        default: true,
    }
});

const textAreaDefaultValue = props.type === "textarea" ? props.parent_ref : "";

const slots = useSlots();

const emit = defineEmits(["update:parent_ref"]);
const refVal = toRef(props, "parent_ref");
const update = (data) => {
    if (props.type === "number") {
        data = Number(data);
    }
    emit("update:parent_ref", data);
}

const isCorrect = ref(0);

if (props.check) {
    watch(refVal, (val) => {
        const check = props.check(val);
        if (check && !val) {
            isCorrect.value = 0;
            return;
        }
        isCorrect.value = Number(props.check(val)) + 1;
    });
}
</script>

<style lang="scss">
.form-group {
  label {
    color: #2d3748;
    font-size: 1.1rem;
    font-weight: 600;
  }

  .subtitle {
    font-size: 0.8rem;
    margin-top: 0.25em;
  }

  .input-group {
    position: relative;
    margin-top: 0.5em;

    input,
    textarea,
    select {
      font-size: 1.05rem;
      padding: 0.35em 0.5em;
      border: 1px solid #e9edf3;
      border-radius: 6px;
      width: calc(100% - 1em);

      &::placeholder {
        color: #cfd6df;
      }
    }

    select {
        width: 100%;
    }
  }
}

form button[type="submit"] {
  color: #38a169;
  border: 1px solid #38a169;
  padding: 0.5em 1em;
  font-size: 1.1rem;
  background: #ffffff;
  cursor: pointer;

  &:hover,
  &:focus {
    background: #38a169;
    color: #ffffff;
  }
}
</style>